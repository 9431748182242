<template>
  <v-container fluid no-gutters>
    <!-- <v-window v-model="mode"> -->
    <!-- <v-window-item :value="'list'"> -->
    <v-row>
      <v-col>
        <h3>
          {{ $t(`Quotations`) }}
        </h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center" v-if="qouteRequests.technicians.length">
      <v-col sm="8">
        <p>
          Our search has not discovered a required expert who could arrive to your production site.
          Your next option - the best REMOTE expert and LOCAL technician both working as one pair.
          They will help you. Select them here.
        </p>
      </v-col>
    </v-row>
    <v-row v-if="qouteRequests.technicians.length">
      <v-col class="d-flex justify-center">
        <v-btn-toggle
          class="styled-btn-group"
          v-model="activeTab"
          rounded
          mandatory
          active-class="active-tab-btn"
        >
          <v-btn :outlined="activeTab !== 'experts'" color="primary" value="experts">
            {{ $t("Experts") }}
          </v-btn>
          <v-btn :outlined="activeTab !== 'technicians'" color="primary" value="technicians">
            {{ $t("Technicians") }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-window v-model="activeTab">
      <v-window-item value="experts">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" v-for="q in qouteRequests.experts" :key="q.id">
              <v-card class="pa-6 mb-6 quote-request quote-shadow">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col sm="1">
                    <v-avatar width="88px" height="88px" rounded>
                      <v-img
                        v-if="q.performer_info.avatar_url"
                        alt="Avatar"
                        :src="q.performer_info.avatar_url"
                      />
                      <v-icon v-else color="secondary">mdi-account</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col sm="2" class="pl-2">
                    <p class="v-chip--clickable" @click="goToPerformerProfile(q.performer_info)">
                      {{ q.performer_info.name }}
                    </p>
                    <p>
                      <span class="text--disabled">{{ $t("Licenses") }}: </span>
                      <span class="pl-4">
                        {{ q.performer_info.licenses.length }}
                      </span>
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-4"></v-divider>
                  <v-col>
                    <table class="quote-request__table">
                      <tr>
                        <td class="quote-request__table-item text--disabled ">
                          {{ $t("Estimated cost, $") }}:
                        </td>
                        <td class="quote-request__table-item float-right font-weight-black pl-2">
                          ${{ q.estimated_cost | currency }}
                        </td>
                      </tr>
                      <tr>
                        <td class="quote-request__table-item text--disabled ">
                          {{ $t("Estimated service time, days") }}:
                        </td>
                        <td class="quote-request__table-item float-right font-weight-black pl-2">
                          {{ q.estimated_service_time }} {{ $t("days") }}
                        </td>
                      </tr>
                    </table>
                  </v-col>

                  <v-col sm="3" class="px-2">
                    <v-btn
                      outlined
                      color="primary"
                      block
                      @click="viewQuotation(q.quotation_doc.id)"
                    >
                      {{ $t(`View Quotation`) }}
                    </v-btn>
                  </v-col>
                  <v-col sm="2" class="px-2">
                    <v-btn
                      color="primary"
                      :disabled="isContractDeclined(q) || isRepairmentSkipped(q)"
                      block
                      :outlined="!q.reviewed"
                      @click="open(q)"
                    >
                      {{ $t(`Open`) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
      <v-window-item value="technicians">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" v-for="q in qouteRequests.technicians" :key="q.id">
              <v-card class="pa-6 mb-6 quote-request quote-shadow">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col sm="1">
                    <v-avatar width="88px" height="88px" rounded>
                      <v-img
                        v-if="q.performer_info.avatar_url"
                        alt="Avatar"
                        :src="q.performer_info.avatar_url"
                      />
                      <v-icon v-else color="secondary">mdi-account</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col sm="2">
                    <p>
                      {{ q.performer_info.name }}
                    </p>
                    <p>
                      <span class="text--disabled">{{ $t("Licenses") }}: </span>
                      <span class="pl-4">{{ q.performer_info.licenses.length }}</span>
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-4"></v-divider>
                  <v-col>
                    <table class="quote-request__table">
                      <tr>
                        <td class="quote-request__table-item text--disabled ">
                          {{ $t("Estimated cost, $") }}:
                        </td>
                        <td class="quote-request__table-item float-right font-weight-black pl-2">
                          ${{ q.estimated_cost | currency }}
                        </td>
                      </tr>
                      <tr>
                        <td class="quote-request__table-item text--disabled ">
                          {{ $t("Estimated service time, days") }}:
                        </td>
                        <td class="quote-request__table-item float-right font-weight-black pl-2">
                          {{ q.estimated_service_time }} {{ $t("days") }}
                        </td>
                      </tr>
                    </table>
                  </v-col>

                  <v-col sm="3" class="px-2">
                    <v-btn
                      outlined
                      color="primary"
                      block
                      @click="viewQuotation(q.quotation_doc.id)"
                    >
                      {{ $t(`View Quotation`) }}
                    </v-btn>
                  </v-col>
                  <v-col sm="2" class="px-2">
                    <v-btn
                      color="primary"
                      block
                      :disabled="isContractDeclined(q) || isRepairmentSkipped(q)"
                      :outlined="!q.reviewed"
                      @click="open(q)"
                    >
                      {{ $t(`Open`) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import { getQuotationForTicket, getQuotationPDF } from "@/api";
import { getQuoteRequestPDF } from "../../../api";
export default {
  data: () => ({
    activeTab: "experts",
    qouteRequests: { experts: [], technicians: [] },
    loading: false
  }),
  computed: {},
  methods: {
    goToPerformerProfile(q) {
      this.$router.push({
        name: "ShowUserProfile",
        params: { id: q.performer_id },
        query: { profile_view: "expert-profile" }
      });
    },
    isOpenButtonDisabled(q) {
      let isDisabled = !q.reviewed;
      if (isDisabled) {
        const isTech = this.qouteRequests.technicians.filter(
          u => u.reviewed && u.aasm_state !== "declined"
        );
        const isExpert = this.qouteRequests.experts.filter(
          u => u.reviewed && u.aasm_state !== "declined"
        );
        isDisabled = this.qouteRequests.technicians.length
          ? isTech.length && isExpert.length
          : isTech.length || isExpert.length;
      }

      return Boolean(isDisabled);
    },
    isContractDeclined(q) {
      return Boolean(q.aasm_state === "declined");
    },
    isRepairmentSkipped(q) {
      return Boolean(q.repair_skipped);
    },
    getQouteRequests() {
      getQuotationForTicket(this.$route.params["id"])
        .then(r => {
          this.qouteRequests = r.data;
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => (this.loading = false));
    },
    open(quoteRequest) {
      this.$router.push({ name: "Quotation", params: { qid: quoteRequest.quote_request_id } });
    },
    viewQuotationRequest(qid) {
      getQuoteRequestPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewQuotation(qid) {
      getQuotationPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    }
  },
  mounted() {
    this.getQouteRequests();
  }
};
</script>

<style lang="scss">
.quote-request {
  font-size: 16px;

  &__table {
    display: inline-block;
    table-layout: fixed;
  }
  &__table-item {
    padding: 4px 0;
  }
}
.v-card.quote-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
}
</style>
